import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FunzApiService } from './funz-api.service';
import { ENV } from 'src/environments/environment';
import { NavController } from '@ionic/angular';

declare var growPayment;

@Injectable({ providedIn: 'root' })
export class GrowPaymentService {

  growPaymentResult$: BehaviorSubject<any> = new BehaviorSubject(null);
  paymentInProcess$: BehaviorSubject<any> = new BehaviorSubject(null);
  statusCheckInterval = null;
  private giftcard = false;
  private orderId = null;
  private startCheck = false;
  private orderToken = null;

  constructor(
    private apiService: FunzApiService,
    private navCtrl: NavController
  ) {
  }

  init() {
    return new Promise((resolve, reject) => {
      const configureGrowSdk = () => {
        let config = {
          environment: ENV.production ? 'PRODUCTION' : 'DEV',
          version: 1,
          events: {
            onSuccess: (response) => {
              this.startCheck = true;
              this.growPaymentResult$.next({ type: 'onSuccess', response });
            },
            onFailure: (response) => {
              this.growPaymentResult$.next({ type: 'onFailure', response });
            },
            onError: (response) => {
              this.growPaymentResult$.next({ type: 'onError', response });
            },
            onTimeout: (response) => {
              this.growPaymentResult$.next({ type: 'onTimeout', response });
            },
            onWalletChange: (response) => {
              if (this.startCheck) {
                this.startStatusCheck();
              }
              this.growPaymentResult$.next({ type: 'onWalletChange', response });
            },
          }
        };
        growPayment.init(config).then(res => {
          console.log('Grow payment initialized');
          resolve(true);
        }).catch(() => {
          alert('Grow payment not initialized')
          reject();
        });
      }
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.meshulam.co.il/sdk/gs.min.js';
      s.onload = configureGrowSdk;
      document.getElementsByTagName('head')[0].appendChild(s);
    })
  }

  growPayment(authCode, orderId = null, giftcard = false, orderToken = null) {
    this.orderId = orderId;
    this.giftcard = giftcard;
    this.startCheck = false;
    this.orderToken = orderToken;
    growPayment.renderPaymentOptions(authCode);
  }

  private startStatusCheck() {
    if (this.orderId) {
      this.paymentInProcess$.next(true);
      const method = this.giftcard
        ? this.apiService.giftCardOrderStatus(this.orderId, this.orderToken)
        : this.apiService.donationOrderStatus(this.orderId, this.orderToken);
      this.statusCheckInterval = setInterval(() => {
        method.subscribe(response => {
          const { status, token } = response;
          if (status !== 'pending') {
            if (status === 'paid') {
              if (this.giftcard) {
                this.navCtrl.navigateRoot(['/gift-card-success'], {
                  state: { orderId: this.orderId, token: this.orderToken }
                });
              } else {
                const newToken = token ? token : this.orderToken;
                this.navCtrl.navigateRoot(['/donation-success'], {
                  state: { orderId: this.orderId, token: newToken }
                });
              }
            }
            this.stopStatusCheck();
          }}
        );
      }, 1000);
      setTimeout(() => {
        this.stopStatusCheck();
      }, 480 * 1000);
    }
  }

  private stopStatusCheck() {
    clearInterval(this.statusCheckInterval);
    this.paymentInProcess$.next(false);
    setTimeout(() => this.orderId = null, 1100);
    
  }
}
