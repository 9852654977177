import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { CanActivateAppGuard } from './core/guards/app.guard';
import { OnboardingGuard } from './core/guards/onboarding.guard';
import { BitResultsComponent } from './components/bit-results/bit-results.component';

const routes: Routes = [
  { path: '', canActivate: [CanActivateAppGuard], children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'home',
    },
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    },
    {
      path: 'menu',
      loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
    },
    {
      path: 'category/:slug',
      loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
    }, {
      path: 'my/conversations/:id',
      loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
    }, {
      path: 'my/conversations',
      loadChildren: () => import('./pages/conversations/conversations.module').then(m => m.ConversationsPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'host/:id',
      loadChildren: () => import('./pages/host/host.module').then(m => m.HostPageModule)
    }, {
      path: 'organization/:id',
      loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationPageModule)
    }, {
      path: 's/:slug',
      loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
    }, {
      path: 'my/profile',
      loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
      canActivate: [AuthGuard]
    }, {
      path: 'my/profile/edit',
      loadChildren: () => import('./pages/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule),
      canActivate: [AuthGuard]
    }, {
      path: 'my/credit',
      loadChildren: () => import('./pages/profile-credits/profile-credits.module').then(m => m.ProfileCreditsPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'my/orders',
      loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'my/orders/:id',
      loadChildren: () => import('./pages/order-details/order-details.module').then(m => m.OrderDetailsPageModule)
    }, {
      path: 'donation/:id',
      loadChildren: () => import('./pages/donation/donation.module').then(m => m.DonationPageModule)
    }, {
      path: 'donation-success',
      loadChildren: () => import('./pages/donation-success/donation-success.module').then(m => m.DonationSuccessPageModule)
    }, {
      path: 'gift-card',
      loadChildren: () => import('./pages/gift-card/gift-card.module').then(m => m.GiftCardPageModule)
    }, {
      path: 'gift-card-success',
      loadChildren: () => import('./pages/gift-card-success/gift-card-success.module').then(m => m.GiftCardSuccessPageModule)
    }, {
      path: 'funz/:id',
      loadChildren: () => import('./pages/funz/funz.module').then(m => m.FunzPageModule)
    }, {
      path: 'live/:id',
      loadChildren: () => import('./pages/live/live.module').then(m => m.LivePageModule)
    }, {
      path: 'funz/:id/book/:event_id',
      loadChildren: () => import('./pages/funz/order/order.module').then(m => m.OrderPageModule)
    }, {
      path: 'funz/:id/book',
      loadChildren: () => import('./pages/funz/order/order.module').then(m => m.OrderPageModule)
    }, {
      path: 'funz/:slug/feedbacks',
      loadChildren: () => import('./pages/funz/feedbacks/feedbacks.module').then(m => m.FeedbacksPageModule)
    }, {
      path: 'funz/:id/request-contact',
      loadChildren: () => import('./pages/request-contact/request-contact.module').then(m => m.RequestContactPageModule)
    }, {
      path: 'funz/:id/request-date',
      loadChildren: () => import('./pages/request-date/request-date.module').then(m => m.RequestDatePageModule)
    }, {
      path: 'search',
      loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
    }, {
      path: 'search-results',
      loadChildren: () => import('./pages/search-results/search-results.module').then(m => m.SearchResultsPageModule)
    }, {
      path: 'contact-us',
      loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
    }, {
      path: 'subscription',
      loadChildren: () =>
        import('./pages/susbscription/subscription-settings/subscription-settings.module').then(m => m.SubscriptionSettingsPageModule)
    }, {
      path: 'my/bookmarks',
      loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'my/notifications-settings',
      loadChildren: () =>
        import('./pages/notifications-settings/notifications-settings.module').then(m => m.NotificationsSettingsPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'invite',
      loadChildren: () => import('./pages/share/share.module').then(m => m.SharePageModule)
    }, {
      path: 'my/notifications',
      loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
      canActivate: [AuthGuard],
    }, {
      path: 'my/loyalty_points',
      loadChildren: () => import('./pages/loyalty-balance/loyalty-balance.module').then(m => m.LoyaltyBalancePageModule),
      canActivate: [AuthGuard],
    }
  ]},
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPagesModule),
    canActivate:  [OnboardingGuard]
  },
  {
    path: 'bit_payment',
    component: BitResultsComponent,
  },
  {
    path: 'bit_payment/:result',
    component: BitResultsComponent,
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
