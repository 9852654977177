import { createSelector } from '@ngrx/store';
import { ICoreState } from './core.reducers';
import { IAppState } from '../app.reducers';
import { SiteHeaders } from '../../models/siteHeaders.model';
import { keys } from 'lodash';

export const getCore = (state: IAppState) => state.core;
export const getBootstrapped = createSelector(
  getCore,
  (core: ICoreState) => core.bootstrapped
);
export const getRootPage = createSelector(
  getCore,
  (core: ICoreState) => core.rootPage
);
export const getRequestedSubPage = createSelector(
  getCore,
  (core: ICoreState) => core.requestedSubPage
);
export const getAuthHeaders = createSelector(
  getCore,
  (core: ICoreState) => core.authHeaders
);
export const getIsSignedIn = createSelector(
  getCore,
  (core: ICoreState) => core.signedIn
);
export const getRequestedSubPageParams = createSelector(
  getCore,
  (core: ICoreState) => core.requestedSubPageParams
);
export const getDeeplinkedPageParams = createSelector(
  getCore,
  (core: ICoreState) => core.deeplinkedPageParams
);
export const getNetworkStatus = createSelector(
  getCore,
  (core: ICoreState) => core.isNetworkConnected
);
export const getLocationStatus = createSelector(
  getCore,
  (core: ICoreState) => core.isLocationAvailable
);
export const getSiteHeaders = createSelector(
  getCore,
  (core: ICoreState) => core.siteHeaders
);
export const getSiteHeadersLocationSuffix = createSelector(
  getSiteHeaders,
  (headers) => {
    const siteId = headers.site_id;
    switch (siteId) {
      case 2 : return 'il';
      case 3 : return 'uk';
      case 6 : return 'us';
      default: return 'uk';
    }
  }
);
export const getCurrentCategory = createSelector(
  getCore,
  (core: ICoreState) => core.currentCategory
);
export const getRootDomain = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.root_domain
);
export const getSocialUsernames = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.social_usernames
);
export const getSiteId = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.site_id
);
export const getBaseDomain = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.base_domain
);
export const getCurrencyCode = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.currency_code
);
export const getSelectedLanguage = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.locales
);
export const getSubDomain = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.subdomain
);
export const getDomain = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.domain
);
export const getCityOnBoarding = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.city_on_boarding
);
export const getSupportEmail = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.support_email
);
export const getSupportPhone = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.support_phone
);
export const getSupportedSite = createSelector(
  getSiteHeaders,
  (siteHeaders: SiteHeaders) => siteHeaders.supported_site
);
export const getLoginPreviewUrl = createSelector(
  getCore,
  getSiteHeaders,
  (core: ICoreState, siteHeaders: SiteHeaders) => {
    return siteHeaders.mobile_preview_image;
  }
);
export const getLoginVideoUrl = createSelector(
  getCore,
  getSiteHeaders,
  (core: ICoreState, siteHeaders: SiteHeaders) => {
    return siteHeaders.mobile_video;
  }
);
export const getMarketingOptIn = createSelector(
  getCore,
  (core: ICoreState) => core.marketingOptIn
);
export const getIsFirstRun = createSelector(
  getCore,
  (core: ICoreState) => core.firstTime
);
export const getDeeplinkString = createSelector(
  getCore,
  (core: ICoreState) => core.deeplinkString
);
export const getAuthGuardInitiatedAuthentication = createSelector(
  getCore,
  (core: ICoreState) => core.authGuardInitiatedAuthentication
);
export const getIsGelocationPrompted = createSelector(
  getCore,
  (core: ICoreState) => core.isGelocationPrompted
);
export const getLastSelectedCityId = createSelector(
  getCore,
  (core: ICoreState) =>  core.lastSelectedCityId
);
export const getPwaDeeplinkedSlug = createSelector(
  getCore,
  (core: ICoreState) => core.pwaDeeplinkedSlug
);
export const getPwaDeeplink = createSelector(
  getCore,
  (core: ICoreState) => core.pwaDeeplink
);
export const getLocalStorageHeadersLoaded = createSelector(
  getCore,
  (core: ICoreState) => core.localStorageHeadersLoaded
);
export const getPwaRequestedPage = createSelector(
  getCore,
  (core: ICoreState) => core.pwaRequestedPage
);
export const getPwaRequestedPageParams = createSelector(
  getCore,
  (core: ICoreState) => core.pwaRequestedPageParams
);
export const getPwaShowFooter = createSelector(
  getCore,
  (core: ICoreState) => core.pwaShowFooter
);
export const getIsAuthHeadersSaved = createSelector(
  getCore,
  (core: ICoreState) => core.authHeadersSaved
);
export const getIsSiteHeadersSaved = createSelector(
  getCore,
  (core: ICoreState) => core.siteHeadersSaved
);
export const getIsAuthAndSiteHeadersSaved = createSelector(
  getIsAuthHeadersSaved,
  getIsSiteHeadersSaved,
  (isAuthHeadersSaved: boolean, isSiteHeadersSaved: boolean) => isAuthHeadersSaved && isSiteHeadersSaved
);
export const getMarketingParams = createSelector(
  getCore,
  (core: ICoreState) => core.marketingParams
);
export const getMarketingParamsLoaded = createSelector(
  getMarketingParams,
  (marketingParams) => keys(marketingParams).length > 0
);
