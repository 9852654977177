import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizeDate',
  pure: false
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'mediumDate', dateIncrease = null): any {
    let timeZoneOffset;
    const dateValue = new Date(value)
    if (value.includes('Z')) {
      timeZoneOffset = dateValue.getTimezoneOffset();
    } else if (value.includes('+')) {
      timeZoneOffset = parseInt(value.split('+').pop(), 10);
    }
    if (dateIncrease) {
      dateValue.setDate(dateValue.getDate() + dateIncrease)
    }
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(dateValue, pattern, `UTC+${timeZoneOffset}`);
  }
}
