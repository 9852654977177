<div class="signup-form">
  <div class="form-header">
    <h2>{{ 'pages.authentication.register.welcome_to' | translate }}</h2>
  </div>
  <div class="signup-form-logo">
    <img *ngIf="!isWadoo" src="assets/imgs/new_funz_logo_splash.png" />
    <img *ngIf="isWadoo" src="assets/imgs/n2-wadoo-logo.svg" />
  </div>
  <div class="main-error-text" *ngIf="showMainError">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.form.errors' | translate}}
  </div>

  <div class="facebook-error-text" *ngIf="facebookSignError$ | async">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.facebook_could_signup' | translate}}
  </div>
  <button class="social-btn facebook-button-signup" (click)="doFacebookSignUp()">
    <div class="facebook-icon-wrapper">
      <img src="assets/imgs/facebook-icon.svg" />
    </div>
    <div class="facebook-text">
      {{ 'pages.authentication.signup.continue_facebook' | translate }}
    </div>
  </button>

  <button class="social-btn google-button-signup" (click)="doGoogleSignUp()">
    <div class="facebook-icon-wrapper">
      <img src="assets/imgs/google-icon.svg" />
    </div>
    <div class="google-text">
      {{ 'pages.authentication.signup.continue_google' | translate }}
    </div>
  </button>

  <div *ngIf="cordova && isIos">
    <div class="facebook-error-text" *ngIf="appleSignError$ | async">
      <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.apple_could_signup' | translate }}
    </div>
    <button class="social-btn apple-button-signup" (click)="doAppleSignUp()">
      <div class="apple-icon-wrapper">
        <i class="n2-ico-mask n2-ico-mask__logo-apple"></i>
      </div>
      <div class="apple-text">
        {{ 'pages.authentication.signup.continue_apple' | translate }}
      </div>
    </button>
  </div>

  <div class="or-line login-line">
    <span class="or-text">{{ 'pages.authentication.landing.signin_or' | translate }}</span>
  </div>

  <button class="social-btn facebook-button-signup" (click)="openRegister()">
    <div class="facebook-icon-wrapper">
      <img src="assets/imgs/email-icon.svg" />
    </div>
    <div class="email-text">
      {{ 'pages.authentication.signup.continue_email' | translate }}
    </div>
  </button>

  <div class="footer-login-link">
    <span>{{ 'pages.authentication.register.signin.text' | translate }} 
      <span (click)="openLogin()">{{ 'component.require-auth.login' | translate }}</span>
    </span>
  </div>
</div>
