import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import { IOrderItem } from '../../core/store/activities';
import { take } from 'rxjs/operators';

@Component({
  selector: 'order-meta',
  template: `
  <div class="order-meta card-bg">
    <div class="order-number">{{ 'component.order.order-meta.order_number' | translate }} {{ order.id }}</div>
    <div class="order-date">{{ order.start_time | localizeDate: dateFormat }}</div>
  </div>`,
  styleUrls: ['order-meta.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderMetaComponent implements OnInit {

  dateFormat = 'EEEE, d MMMM y, HH:mm';

  @Input() order: IOrderItem;

  constructor(private store: Store<fromApp.IAppState>) {
  }

  ngOnInit() {
    this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(take(1)).subscribe(suffix => {
      this.dateFormat = suffix === 'us' ? 'EEEE, MMMM d, y, hh:mm a' : 'EEEE, d MMMM y, HH:mm'
    });
  }
}
