import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { combineLatest, BehaviorSubject, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import * as fromCore from '../store/core';
import * as fromApp from '../../core/store/app.reducers';
import * as fromProfile from '../../core/store/profile';
import * as fromFunz from '../../shared-stores/funz/store';
import { IProfileState } from '../../core/store/profile';
import { get, merge, keys, set, unset, has, toPairs, fromPairs } from 'lodash';
import { takeWhile, take, switchMap, takeUntil } from 'rxjs/operators';
import { ENV } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as LogRocket from 'logrocket';
// import * as Sentry from 'sentry-cordova';
import { NO_CITY_ID } from '../../funz/city.model';
import { ParamsService } from './params.service';
import { GeneralHelperService } from './general-helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Google Tagmanager dataLayer events
export const ADAPTIVE_BOOK_NOW = 'bookNow';
export const ECOMMERCE_SUCCESS = 'ecomSuccess';
export const ADAPTIVE_COMMENT_FEEDBACK = 'comment_Feedback';
export const ADAPTIVE_CUSTOMER_LOGIN = 'login_Success';
export const ADD_TO_CART = 'addToCart';
export const PAGE_CHANGE = 'page_change';
export const ADAPTIVE_NEW_REGISTRATION = 'register_Success';
export const ADAPTIVE_SHARE_OF_CONTENT = 'Shared_content';
export const FB_COMPLETE_REGISTRATION = 'register_complete';
export const FEEDBACK_LIKE = 'feedback_like';
export const FEEDBACK_UNLIKE = 'feedback_unlike';

import moment from 'moment';

@Injectable()
export class TrackingService {
  ordersTracked: { [orderId: string]: boolean } = {};
  windowObj = window as any;
  cordova = !!(window as any).cordova;
  isSignedIn: boolean = null;
  user: IProfileState;
  userIdentifyStatus: boolean = null;
  baseDomain: string;
  private subscriptions$: { [s: string]: BehaviorSubject<boolean> } = {};
  canSendFirebaseEvents = false;
  oneSignalPlayerId: '';
  public appVersion: string;

  constructor(
    private store: Store<fromApp.IAppState>,
    private cookieService: CookieService,
    private platform: Platform,
    private httpClient: HttpClient,
    private storage: Storage,
    private firebase: FirebaseX,
    private paramsService: ParamsService,
    private ghs: GeneralHelperService,
  ) {
    this.platform.ready().then(() => {
      this.saveUrlParams();
      if (this.windowObj.cordova) {
        this.canSendFirebaseEvents = true;
      }
    });
    this.initializeCooladata();
  }

  log(...args) {
    if (this.windowObj.fdebug) {
      console.log.apply(console, args);
    }
  }

  trackCooladata(eventName = null, data = {}) {
    if (this.subscriptions$[eventName]) {
      this.subscriptions$[eventName].unsubscribe();
      delete this.subscriptions$[eventName];
    }
    this.subscriptions$[eventName] = new BehaviorSubject(false);
    combineLatest([
      this.store.select(fromCore.getSiteHeaders),
      this.store.select(fromProfile.getProfile),
      this.store.select(fromCore.getIsSignedIn),
      this.subscriptions$[eventName],
      this.store.select(fromCore.getMarketingParams),
      this.store.select(fromCore.getLastSelectedCityId)]
    ).pipe(takeWhile(([siteHeaders, user, isSignedIn, keepGoing, marketingParams, cityId]) => keepGoing === false))
    .subscribe(([siteHeaders, user, isSignedIn, keepGoing, marketingParams, cityId]) => {
      this.user = user;
      this.isSignedIn = isSignedIn;
      if (isSignedIn === null || (isSignedIn === true && (!get(user, 'user_id') || !get(siteHeaders, 'site_id')))) {
        return;
      }
      this.subscriptions$[eventName].next(true);
      let userFields: any = {};
      if (isSignedIn) {
        userFields = this.userTrackingFields(user);
      }
      const deviceData = (window as any).cordova ? (window as any).device : {};
      const deviceDataWithPrefix = fromPairs(
        toPairs(deviceData).map(([key, value]) => [`f_device_${key}`, value])
      );
      merge(data, this.commonTrackingFields(siteHeaders, cityId), userFields, marketingParams, deviceDataWithPrefix);
      const cleanData = this.cleanObject(data);
      this.callCoola(eventName, cleanData);
      if (this.canSendFirebaseEvents) {
        this.firebase.logEvent(eventName, { event_name: eventName, ...cleanData, version: this.appVersion || '' });
      }
    });
  }

  trackAppsFlyer(eventName = null, data = {}) {
    this.windowObj.plugins.appsFlyer.trackEvent(eventName, data);
  }

  cleanObject(obj) {
    const objCleaned = {};
    keys(obj).forEach((item) => {
      const currentValue = obj[item];
      if (currentValue !== '' && currentValue !== null) {
        objCleaned[item] = currentValue;
      }
    });
    return objCleaned;
  }

  getUserId() {
    return get(this, 'user.user_id') || this.cookieService.get('ahoy_visitor') || get(this.windowObj, 'cooladata.config.user_id');
  }

  userIdentify() {
    if (this.userIdentifyStatus || this.userIdentifyStatus === this.isSignedIn || !this.cordova) {
      return;
    }
    this.userIdentifyStatus = this.isSignedIn;
    const userId = this.getUserId();
    const userData = this.cleanObject({
      user_id: userId,
      name: get(this, 'user.name'),
      email: get(this, 'user.email')
    });
    // Sentry.configureScope(scope => {
    //   scope.setUser({
    //     id: userId,
    //     email: get(this, 'user.email'),
    //     username: get(this, 'user.name')
    //   });
    // });
    const logRocketUser = get(userData, 'user_id', 'USER_DETAILS');
    LogRocket.log(`LogRocket.identify ${logRocketUser}`, JSON.stringify(userData, null, 2));
    LogRocket.identify(`${logRocketUser}`, userData);
    this.firebase.setUserId(userId);
    this.log(`LogRocket.identify(${logRocketUser}, ${JSON.stringify(userData, null, 2)})`);
  }

  callCoola(eventName, data) {
    // if (eventName === 'order_paid') {
    //   console.log('CALLCOOLA', eventName, data)
    // }
    if (data.expires_at) {
      data = { ...data, expires_at: moment(data.expires_at).unix() }
    }
    if (data.utm_expires_at) {
      data = { ...data, utm_expires_at: moment(data.utm_expires_at).unix() }
    }
    if (data.original_expires_at) {
      data = { ...data, original_expires_at: moment(data.original_expires_at).unix() }
    }
    // setTimeout(() => {
    //   if (this.windowObj.cooladata) {
    //     if (!eventName) {
    //       // no eventname passed for cooladata. exiting
    //       return;
    //     }
    //     this.userIdentify();
    //     LogRocket.track(`cooladata event ${eventName}`);
    //     this.log(`cooladata event: ${eventName}`, JSON.stringify(data, null, 2));
    //     LogRocket.log(`cooladata event: ${eventName}`, JSON.stringify(data, null, 2));
    //     this.windowObj.cooladata.trackEvent(eventName, data);
    //     return true;
    //   }
    // });
  }

  private commonTrackingFields(siteHeaders, cityId) {
    return {
      site_id: get(siteHeaders, 'site_id'),
      is_mobile: true,
      pwa: true,
      user_id: this.cookieService.get('ahoy_visitor'),
      version: this.appVersion,
      source_platform: this.cordova ? 'app' : 'mobile-web',
      onesignal_player_id: this.oneSignalPlayerId,
      city_id: cityId,
      ...this.utmCookies()
    };
  }

  updateOneSignalPlayerId(oneSignalPlayerId) {
    this.oneSignalPlayerId = oneSignalPlayerId;
  }

  private userTrackingFields(user) {
    return {
      provider: user.provider,
      user_alternative_id: user.user_id,
      user_fb_user: user.provider === 'facebook',
      user_funzing_buyer: user.onesignal_paying,
      user_funzing_user: !!user.user_id,
      user_logged_in: !!user.user_id,
      user_sign_in_count: user.sign_in_count,
      onesignal_paying: user.onesignal_paying
    };
  }

  pushToDataLayer(dataObject) {
    LogRocket.log('datalayer push', JSON.stringify(dataObject, null, 2));
    this.log('datalayer push', JSON.stringify(dataObject, null, 2));
    this.windowObj.dataLayer.push(dataObject);
  }

  trackGAEvent(eventName, payload = {}) {
    const data = merge({ event: eventName }, payload);
    LogRocket.track(`GA event ${eventName}`);
    this.pushToDataLayer(data);
  }

  funzPageDataLayer(funz) {
    const adultPrice = this.removeCurrency(funz.adultPrice);
    const kidsPrice = this.removeCurrency(funz.kidsPrice);
    const data = {
      FunzCoreCategory: funz.categoryName,
      FunzOnKidsCategory: funz.funzOnKidsCategory,
      FunzAdultPrice: adultPrice,
      FunzKidsPrice: kidsPrice,
      FunzInterests: funz.funzInterests,
      FunzForKids: funz.intendedForChildren,
      FunzID: funz.id,
      HostID: funz.owner.id,
      FunzTags: funz.tagsCache,
      City: funz.city,
      ecommerce: {
        detail: [{
          name: funz.title,
          id: funz.id,
          price: adultPrice,
          brand: funz.city,
          category: funz.categorySlug,
          variant: funz.categoryName,
          position: 1
        }]
      }
    };
    this.pushToDataLayer(data);
  }

  ecommerceConversionDataLayer(event, order) {
    const totalPrice = this.removeCurrency(order.price_paid_with_money);
    const data = {
      event,
      ecommerce: {
        purchase: {
          actionField: {
            action: 'purchase',
            id: order.id,
            revenue: order.revenue && Number(order.revenue) > 0 ? order.revenue : '0'
          },
          products: [{
            name: order.title,
            id: order.funz_id,
            price: totalPrice,
            category: order.category_slug,
            brand: order.city,
            quantity: 1
          }]
        }
      },
      transactionRevenue: order.revenue && Number(order.revenue) > 0 ? order.revenue : '0',
      productName: order.title
    };
    const ecomSuccessEvents = this.windowObj.dataLayer.filter(item => item.event && item.event === 'ecomSuccess');
    if (ecomSuccessEvents.length) {
      if (!ecomSuccessEvents.some(item => item.ecommerce?.purchase?.actionField?.id === order.id)) {
        this.pushToDataLayer(data);
      }
    } else {
      this.pushToDataLayer(data);
    }
  }

  private removeCurrency(price) {
    return Number(price.replace(/[^0-9.-]+/g, ''));
  }

  orderTracking(event, trackingData) {
    // TODO: remove this temp fix by refactoring the setInterval in ORDER_FUNZ_STATUS_CHECK
    if (event === 'order_paid') {
      if (get(this.ordersTracked, trackingData.order_id) === true) {
        return;
      } else {
        set(this.ordersTracked, `${trackingData.order_id}`, true);
      }
    }

    this.store.select(fromFunz.getFunzTrackingStatus(trackingData.funz_id)).pipe(take(1)).subscribe((status: string) => {
      if (status) {
        this.traceAction(event, { ...trackingData, status });
      }
    });
  }

  searchFilterTracking(eventName, filterObject, city, type = 'action') {
    if (!filterObject) { return; }
    const preparedData = {
      query: filterObject.query,
      category_ids: filterObject.categories.map(category => category.id),
      // location: city.id !== NO_CITY_ID ? city.name : 'anywhere',
      date_range: filterObject.date.type === 'from_date' ?
        `${filterObject.date.value.from} - ${filterObject.date.value.to}` :
        filterObject.date.type,
      handicapped_accessible: filterObject.handicap_accessible,
      min_price_range: filterObject.price.lower,
      max_price_range: filterObject.price.upper,
      start_time: filterObject.day_part,
      interests: filterObject.interests.map(interest => interest.display_name),
    };
    if (type === 'action') {
      this.traceAction(eventName, preparedData);
    } else {
      this.tracePageView(eventName, preparedData);
    }
  }

  traceAction(eventName, data: any = {}) {
    this.trackCooladata(eventName, { ...data, event_type: 'action' });
  }

  tracePageView(eventName, data: any = {}) {
    this.trackCooladata(eventName, { ...data, event_type: 'page_view' });
  }

  async saveUrlParams(marketingParams = null) {
    if (this.cordova) {
      if (marketingParams) {
        const utms = await this.handleUtms(marketingParams);
        // alert('UTMS to save ' + JSON.stringify(utms));
        this.store.dispatch(new fromCore.SaveMarketingParams(utms));
        this.sendUtms();
      }
    } else {
      const urlParamsObj = this.paramsService.latestUrlParams();
      if (urlParamsObj && !urlParamsObj.gift_card_purchase) {
        const utms = await this.handleUtms(urlParamsObj);
        this.store.dispatch(new fromCore.SaveMarketingParams(utms));
        this.sendUtms();
      }
    }
  }

  async handleUtms(marketingParams) {
    const mp = Object.assign({}, marketingParams);
    const originalUtms = {};
    const expires_at = new Date(Date.now() + (3600 * 1000 * 24 * 28));
    const wantedCookies = ['utm_medium', 'utm_source', 'utm_term', 'utm_content', 'utm_campaign', 'ad_id'];
    let isSellerDirect;
    if (this.cordova) {
      await this.storage.get('utm_medium').then(value => {
        isSellerDirect = value === 'seller_direct';
      })
    } else {
      isSellerDirect = this.cookieService.get('utm_medium') === 'seller_direct';
    }

    // 1. Handle original utms
    await Promise.all(Object.entries(mp).map(async ([cookieName, val]) => {
      const originalCookieName = `original_${cookieName}`;

      if (wantedCookies.includes(cookieName) && val) {
        if (this.cordova) {
          const storageItem = await this.storage.get(originalCookieName);
          if (!storageItem) {
            this.storage.set(originalCookieName, val);
            set(originalUtms, originalCookieName, val);
          } else {
            set(originalUtms, originalCookieName, storageItem);
          }
          if (!isSellerDirect) {
            this.storage.set(cookieName, val);
          }
        } else {
          if (!this.cookieService.check(originalCookieName)) {
            this.cookieService.set(originalCookieName, val as string, expires_at, '/', ENV.core_domain);
            set(originalUtms, originalCookieName, val);
          } else {
            set(originalUtms, originalCookieName, this.cookieService.get(originalCookieName));
          }
          if (!isSellerDirect) {
            this.cookieService.set(cookieName, val as string, expires_at, '/', ENV.core_domain);
          }
        }
      }
    }));

    // 2. Handle original_expires_at
    const found = Object.entries(originalUtms).filter(([key, value]) => !!value);
    if (found && found.length) {
      if (this.cordova) {
        const originalExpiresAt = await this.storage.get('original_expires_at');
        if (!originalExpiresAt) {
          this.storage.set('original_expires_at', expires_at.toUTCString());
          set(originalUtms, 'original_expires_at', expires_at.toUTCString());
        } else {
          set(originalUtms, 'original_expires_at', originalExpiresAt);
        }
      } else {
        if (!this.cookieService.check('original_expires_at')) {
          this.cookieService.set('original_expires_at', expires_at.toUTCString(), expires_at, '/', ENV.core_domain);
          set(originalUtms, 'original_expires_at', expires_at.toUTCString());
        } else {
          set(originalUtms, 'original_expires_at', this.cookieService.get('original_expires_at'));
        }
      }

      // 3. Handle utm_expires_at
      if (this.cordova) {
        this.storage.set('utm_expires_at', expires_at.toUTCString());
      } else {
        this.cookieService.set('utm_expires_at', expires_at.toUTCString(), expires_at, '/', ENV.core_domain);
      }
    }

    return {
      ...marketingParams,
      ...originalUtms,
      expires_at: expires_at.toUTCString(),
    }
  }

  checkUtmsStorageExpiration(marketingParams) {
    if (this.cordova && marketingParams) {
      const utmsList = ['expires_at', 'utm_expires_at', 'ad_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
      if (marketingParams.expires_at && Date.parse(marketingParams.expires_at) < Date.parse(new Date().toUTCString())) {
        utmsList.map(key => {
          delete marketingParams[key];
          this.storage.remove(key);
        })
      }
      if (marketingParams.original_expires_at && Date.parse(marketingParams.original_expires_at) < Date.parse(new Date().toUTCString())) {
        utmsList.map(key => {
          delete marketingParams['original_' + key];
          this.storage.remove('original_' + key);
        })
      }
    }
    return marketingParams;
  }

  sendUtms(isSignIn = false) {
    return new Promise((resolve) => {
      const stop$ = new Subject<boolean>();
      this.store.select(fromCore.getIsSiteHeadersSaved).pipe(takeUntil(stop$)).subscribe(fetched => {
        if (fetched) {
          this.store.select(fromCore.getBaseDomain).pipe(take(1)).subscribe(baseDomain => {
            const URL = `${baseDomain}/my/utms`;
            const destroy$ = new Subject<boolean>();
            this.store.select(fromCore.getIsSignedIn).pipe(takeUntil(destroy$)).subscribe(isSigned => {
              if (isSigned) {
                this.store.select(fromCore.getMarketingParams).pipe(take(1)).subscribe(params => {
                  const cooladataUserId = this.cordova && this.windowObj.cooladata && this.windowObj.cooladata.config
                    ? this.windowObj.cooladata.config.user_id
                    : this.cookieService.get('cd_user_id');
                  if (!isSignIn) {
                    resolve(true);
                  }
                  this.httpClient.post<any>(URL, {
                    utms: { ...params, cooladata_user_id: cooladataUserId ? cooladataUserId : null },
                    is_signin: isSignIn
                  }, {
                    observe: 'body',
                    responseType: 'json',
                    withCredentials: true,
                    headers: new HttpHeaders ({ 'Content-Type': 'application/json' })
                  }).subscribe(async marketingParams => {
                    if (marketingParams && isSignIn) {
                      if (marketingParams.cooladata_user_id) {
                        const expires_at = new Date(Date.now() + (3600 * 1000 * 24 * 28));
                        setTimeout(() => {
                          this.cookieService.delete('cd_user_id', '/', location.hostname.replace('m', ''));
                          this.cookieService.set(
                            'cd_user_id',
                            marketingParams.cooladata_user_id,
                            expires_at,
                            '/',
                            location.hostname.replace('m', '')
                          );
                        }, 1000);
                      }
                      this.handleServerMarketingParams(marketingParams);
                      resolve(true);
                    } else {
                      resolve(true);
                    }
                  }, err => {
                    console.log('MARKETING PARAMS ERR', JSON.stringify(err));
                    resolve(true);
                  });
                })
              } else {
                resolve(true);
              }
              if (isSigned !== null) {
                destroy$.next(true);
                destroy$.complete();
              }
            })
          });
          stop$.next(true);
          stop$.complete();
        } else {
          resolve(true);
        }
      })
    })
  }

  handleServerMarketingParams(params) {
    const newParams = {}
    if (params.expires_at && new Date(params.expires_at).getTime() > new Date().getTime()) {
      const newParamsMap = {
        ad_id: params.ad_id ? params.ad_id : null,
        utm_expires_at: params.expires_at ? params.expires_at : null,
        utm_campaign: params.utm_campaign ? params.utm_campaign : null,
        utm_content: params.utm_content ? params.utm_content : null,
        utm_medium: params.utm_medium ? params.utm_medium : null,
        utm_source: params.utm_source ? params.utm_source : null,
        utm_term: params.utm_term ? params.utm_term : null
      };
      Object.assign(newParams, Object.entries(newParamsMap).filter(([key, value]) => {
        if (value) {
          this.cordova
            ? this.storage.set(key, value)
            : this.cookieService.set(key, value, new Date(params.expires_at), '/', ENV.core_domain);
          return value;
        }
      }).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {}));
    }
    const newOriginalParams = {}
    if (params.original_expires_at){
      if (new Date(params.original_expires_at).getTime() > new Date().getTime()) {
        const newOriginalParamsMap = {
          original_ad_id: params.original_ad_id ? params.original_ad_id : null,
          original_expires_at: params.original_expires_at ? params.original_expires_at : null,
          original_utm_campaign: params.original_utm_campaign ? params.original_utm_campaign : null,
          original_utm_content: params.original_utm_content ? params.original_utm_content : null,
          original_utm_medium: params.original_utm_medium ? params.original_utm_medium : null,
          original_utm_source: params.original_utm_source ? params.original_utm_source : null,
          original_utm_term: params.original_utm_term ? params.original_utm_term : null
        }
        Object.assign(newOriginalParams, Object.entries(newOriginalParamsMap).filter(([key, value]) => {
          if (value) {
            this.cordova
              ? this.storage.set(key, value)
              : this.cookieService.set(key, value, new Date(params.original_expires_at), '/', ENV.core_domain);
            return value;
          }
        }).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {}))
      } else {
        this.cordova ? this.storage.remove('original_ad_id') : this.cookieService.delete('original_ad_id', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_expires_at') : this.cookieService.delete('original_expires_at', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_utm_campaign') : this.cookieService.delete('original_utm_campaign', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_utm_content') : this.cookieService.delete('original_utm_content', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_utm_medium') : this.cookieService.delete('original_utm_medium', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_utm_source') : this.cookieService.delete('original_utm_source', '/', ENV.core_domain);
        this.cordova ? this.storage.remove('original_utm_term') : this.cookieService.delete('original_utm_term', '/', ENV.core_domain);
      }
    }

    // console.log('UTMS TO Save', { ...newParams, ...newOriginalParams })
    this.store.dispatch(new fromCore.SaveMarketingParams({ ...newParams, ...newOriginalParams }));
  }

  utmCookies() {
    const cookiesObj = {};
    const wantedCookies = ['utm_medium', 'utm_source', 'utm_term', 'utm_content', 'utm_campaign', 'ad_id'];
    wantedCookies.forEach((cookieName) => {
      const orderCookieName = `order_${cookieName}`;
      if (this.cookieService.check(orderCookieName)) {
        set(cookiesObj, cookieName, this.cookieService.get(orderCookieName));
      }
    });
    return cookiesObj;
  }

  afBannerLink(additionaParams: any = {}) {
    return this.store.select(fromCore.getMarketingParams).pipe(take(1), switchMap(marketingParams => {
      const data = {
        af_banner: 'true',
        pid: 'Mobile_Web_Banner',
        c: 'App_Download',
        af_ad: 'banner_ad',
        af_ad_id: 'banner_ad_id',
        af_siteid: 'banner_site_id',
        af_sub1: 'banner_sub1',
        af_dp: `funzing://app${location.pathname}`,
        ...marketingParams,
        ...additionaParams,
      };
      const params = new URLSearchParams(data).toString();

      const link = `https://funzing.onelink.me/zuUF?${params}`;

      return of(link);
    }));
  }

  renameKey(obj, oldKey, newKey) {
    if (has(obj, oldKey)) {
      set(obj, newKey, get(obj, oldKey));
      unset(obj, oldKey);
    }
    return obj;
  }

  /* tslint:disable */
  initializeCooladata() {
    const d = (document as any), a = this.windowObj.cooladata || [];
    if (!a.__SV) {
      let b, c, g, e;
      this.windowObj.cooladata = a;
      a._i = [];
      a.init = function(b, c, f) {
        function d(a, b) {
          const c = b.split('.');
          2 == c.length && (a = a[c[0]], b = c[1]);
          a[b] = function() {
            a.push([b].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        let h = a;
        'undefined' !== typeof f ? h = a[f] = [] : f = 'cooladata';
        g = ['trackEvent', 'trackEventLater', 'trackPageload', 'flush', 'setConfig'];
        for (e = 0; e < g.length; e++) { d(h, g[e]); }
        a._i.push([b, c, f]);
      };
      a.__SV = 1.2;
      b = d.createElement('script');
      b.type = 'text/javascript';
      b.async = !0;
      b.src = '/assets/api/cooladata-latest.min.js';
      c = d.getElementsByTagName('script')[0];
      c.parentNode.insertBefore(b, c);
    }
    // this.windowObj.cooladata.init({
    //   app_key: `${ENV.cooladata_app_key}`,
    //   track_pageload: false,
    //   img_src_get_request: false
    // });
  }
}
