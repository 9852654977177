export const ENV = {
  mode: 'TODA',
  default_base_domain: 'https://toda.gives/api/v2',
  default_root_domain: 'https://toda.gives',
  default_root_api_domain: 'https://toda.gives',
  cloudinary_base_url: 'https://res.cloudinary.com/dnn7h5tec/image/upload/',
  cloudinary_image_set: 'prod',
  facebook_app_id: '',
  facebook_app_name: '',
  google_web_application_client_id: '',
  core_domain: 'toda.gives',
  cached_json_external_url: '/assets/api',
  onesignal: {
    il: {
      chrome_id: '',
      safari_id: '',
    },
    uk: {
      chrome_id: '',
      safari_id: '',
    }
  },
  production: true
};
