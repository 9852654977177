// All pages in the app
// These should be used when navCtrl.push is used

export enum APP_PAGES {
  AuthPage = 'AuthPage',
  AuthModalPage = 'AuthModalPage',
  CategoryPage = 'CategoryPage',
  FunzPage = 'FunzPage',
  OrderPage = 'OrderPage',
  DiscoverPage = 'HomePage',
  ProfilePage = 'ProfilePage',
  ProfileEditPage = 'ProfileEditPage',
  SettingsPage = 'SettingsPage',
  ProfileCreditsPage = 'ProfileCreditsPage',
  LoyaltyBalancePage = 'LoyaltyBalancePage',
  ActivitiesPage = 'ActivitiesPage',
  WishlistPage = 'WishlistPage',
  OrderDetailsPage = 'OrderDetailsPage',
  HostPage = 'HostPage',
  SearchPage = 'SearchPage',
  SearchResultsPage = 'SearchResultsPage',
  SharePage = 'SharePage',
  OnboardingPage = 'OnboardingPage',
  SelectCityModalPage = 'SelectCityModalPage',
  DiscoverFilterPage = 'DiscoverFilterPage',
  LoaderPage = 'LoaderPage',
  CategoryLoaderPage = 'CategoryLoaderPage',
  SubscriptionInfoPage = 'SubscriptionInfoPage',
  SubscriptionSettingsPage = 'SubscriptionSettingsPage',
  SeatsSelectPage = 'SeatsSelectPage',
  PaymentFramePage = 'PaymentFramePage',
  PaymentStripePage = 'PaymentStripePage',
  StaticPageFramePage = 'StaticPageFramePage',
  LandingPage = 'LandingPage',
  PrivacySettingsPage = 'PrivacySettingsPage',
  OrdersPage = 'OrdersPage',
  ConversationsPage = 'ConversationsPage',
  NotificationsPage = 'NotificationsPage',
  ErrorPage = 'ErrorPage',
  ChatPage = 'ChatPage',
  RequestDatePage = 'RequestDatePage',
  RequestContactPage = 'RequestContactPage',
  ContactSentPage = 'ContactSentPage',
  FeedbacksPage = 'FeedbacksPage',
  MenuPage = 'MenuPage',
  FacebookConfirmPage = 'FacebookConfirmPage',
  NotificationsSettingsPage = 'NotificationsSettingsPage',
  LivePage = 'LivePage',
  GiftCardPage = 'GiftCardPage'
}

export enum APP_PAGES_TRACKING {
  AuthPage = 'AuthPage',
  AuthModalPage = 'AuthModalPage',
  CategoryPage = 'CategoryPage',
  FunzPage = 'FunzPage',
  DiscoverPage = 'HomePage',
  ProfilePage = 'ProfilePage',
  SettingsPage = 'SettingsPage',
  ActivitiesPage = 'ActivitiesPage',
  WishlistPage = 'WishlistPage',
  OrderDetailsPage = 'OrderDetailsPage',
  HostPage = 'HostPage',
  SearchPage = 'SearchPage',
  SearchResultsPage = 'SearchResultsPage',
  SharePage = 'SharePage',
  OnboardingLocationPage = 'OnboardingLocationPage',
  SelectCityModalPage = 'SelectCityModalPage',
  DiscoverFilterPage = 'DiscoverFilterPage',
  LoaderPage = 'LoaderPage',
  CategoryLoaderPage = 'CategoryLoaderPage',
  SubscriptionInfoPage = 'SubscriptionInfoPage',
  SeatsSelectPage = 'SeatsSelectPage',
  PaymentFramePage = 'PaymentFramePage',
  PaymentStripePage = 'PaymentStripePage',
  StaticPageFramePage = 'StaticPageFramePage',
  FeedbacksPage = 'FeedbacksPage',
  LivePage = 'LivePage'
}
