import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IOrderItem, IOrderDetails } from '../../core/store/activities';
import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'order-details',
  templateUrl: 'order-details.html',
  styleUrls: ['order-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsComponent implements OnInit {

  @Input() order: IOrderItem | IOrderDetails | any;
  @Input() full = false;
  @Input() liveFunz = false;
  @Input() moreLink = true;
  @Input() withSubscription = false;
  timeFormat = 'H:mm'

  constructor(private store: Store<fromApp.IAppState>) {
  }

  ngOnInit() {
    this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(take(1)).subscribe(suffix => {
      this.timeFormat = suffix === 'us' ? 'hh:mm a' : 'H:mm';
    });
  }

  // get time() {
  //   return new Intl.DateTimeFormat('he').format(
  //     new Date(this.order.start_time)
  //   );
  // }
}
