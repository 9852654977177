import { Component, Input, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Store } from '@ngrx/store';
import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import { take, tap } from 'rxjs/operators';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { ENV } from '../../../environments/environment';
import { WindowRef } from '../../core/services/window.ref';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { get } from 'lodash';
import { Device } from '@ionic-native/device/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';
import { TextBasedModalService } from '../text-based-modal/text-based-modal.service';

@Component({
  selector: 'contact-us-modal',
  templateUrl: 'contact-us.modal.html',
  styleUrls: ['./contact-us.modal.scss']
})
export class ContactUsModalComponent implements OnInit {

  env = ENV;
  rootDomain;
  blogLink;
  siteLocationSuffix$ = this.store.select(fromCore.getSiteHeadersLocationSuffix);
  supportEmail$ = this.store.select(fromCore.getSupportEmail);
  supportPhone$ = this.store.select(fromCore.getSupportPhone);
  cordova;
  appVersionNumber;
  codeVersionNumber;
  isHe = false

  constructor(
    private store: Store<fromApp.IAppState>,
    private inAppBrowser: InAppBrowser,
    private modalCtrl: ModalController,
    public platform: Platform,
    private appVersion: AppVersion,
    public device: Device,
    private callNumber: CallNumber,
    private socialSharing: SocialSharing,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private textBasedModalService: TextBasedModalService
  ) {
  }

  ngOnInit(): void {
    this.siteLocationSuffix$.pipe(take(1)).subscribe(suffix => {
      if (suffix === 'uk') {
        this.blogLink = 'https://blog.funzing.com';
      } else if (suffix === 'il') {
        this.blogLink = 'https://blog.il.funzing.com/';
      } else {
        this.blogLink = 'https://blog.wadoo.com/';
      }
    });
    this.store
      .select(fromCore.getRootDomain)
      .subscribe((rootDomain: string) => (this.rootDomain = rootDomain));
    this.cordova = !!(window as any).cordova;
    this.getAppVersionInfo();
    this.isHe = this.translate.currentLang === 'he';
  }

  openUrl(url, full = false) {
    this.textBasedModalService.openModal(url);
  }

  sendEmail() {
    this.supportEmail$.pipe(
      take(1),
      tap(async (mail) => {
        const email = 'support@toda.gives'
        const errorToast =  await this.toastCtrl.create({
          message: this.translate.instant('component.contact-modal.email-client-error'),
          duration: 3000,
          position: 'bottom'
        });
        if (this.cordova) {
          this.socialSharing
            .canShareViaEmail()
            .then(() => {
              this.socialSharing
                .shareViaEmail('', '', [email])
                .then(() => {
                  // Success!
                })
                .catch(() => {
                  errorToast.present();
                });
            })
            .catch(() => {
              errorToast.present();
            });
        } else {
          this.inAppBrowser.create(encodeURI('mailto:' + email), '_self', 'location=no,hideurlbar=yes,zoom=no');
        }
      })
    ).subscribe();
  }

  whatsappMessage() {
    this.inAppBrowser.create(
      encodeURI('https://api.whatsapp.com/send?phone=972545262381'),
      '_blank',
      'location=no,hideurlbar=yes,zoom=no'
    );
  }

  phoneCall() {
    this.supportPhone$.pipe(
      take(1),
      tap((phoneNumber) => {
        if (this.cordova) {
          this.callNumber
            .callNumber(phoneNumber, true)
            .catch(error => this.store.dispatch(new fromCore.HandleError(error)));
        } else {
          this.inAppBrowser.create('tel:' + phoneNumber, '_self', 'location=no,hideurlbar=yes,zoom=no');
        }
      })
    ).subscribe();
  }

  getAppVersionInfo() {
    if (this.cordova) {
      this.appVersion.getVersionCode().then(version => {
        this.codeVersionNumber = version as any;
      });
      this.appVersion.getVersionNumber().then(version => {
        this.appVersionNumber = version;
      });
    } else {
      this.appVersionNumber = get(window as any, 'pwa_version', '');
    }
  }

  get isDevMode() {
    return ENV.mode !== 'Prod';
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

}
