import { ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AppleSignInErrorResponse, AppleSignInResponse, ASAuthorizationAppleIDRequest, SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { FacebookProvider } from 'src/app/shared-stores/auth/providers/facebook';
import { OpenSignIn, OpenSignUp } from 'src/app/shared-stores/auth/store';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import * as fromApp from '../../core/store/app.reducers';
import * as fromAuth from '../../shared-stores/auth/store';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'user-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChildren('error') errors: QueryList<any>;

  showErrors = false;
  showMainError = false;
  windowObj = window as any;
  cordova = !!this.windowObj.cordova;
  facebookSignError$: Observable<boolean> = this.store.select(fromAuth.getFacebookSignError);
  appleSignError$: Observable<boolean> = this.store.select(fromAuth.getAppleSignError);
  isWadoo = this.translateService.getDefaultLang() === 'en-US';
  isIos = this.platform.is('ios');

  constructor(
    private platform: Platform,
    private store: Store<fromApp.IAppState>,
    private trackingService: TrackingService,
    private facebookProvider: FacebookProvider,
    private detectorRef: ChangeDetectorRef,
    private signInWithApple: SignInWithApple,
    private translateService: TranslateService,
    private googlePlus: GooglePlus,
    private socialAuthService: SocialAuthService,
  ) { }

  ngOnInit() { }

  ngDoCheck(): void {
    const newShowMainError = !!(this.errors && this.errors.length);
    if (newShowMainError !== this.showMainError) {
      this.showMainError = newShowMainError;
      this.detectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new fromAuth.ResetFbError());
  }

  doFacebookSignUp() {
    this.showErrors = false;
    this.showMainError = false;
    this.trackingService.traceAction('facebook_login_click', { type: 'Registration' });
    this.store.dispatch(new fromAuth.ShowLoader());
    if (this.cordova) {
      this.store.dispatch(new fromAuth.TryFBSignIn());
    } else {
      this.facebookProvider.signIn();
    }
  }

  doAppleSignUp() {
    this.store.dispatch(new fromAuth.ShowLoader());
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    }).then((res: AppleSignInResponse) => {
      this.store.dispatch(new fromAuth.AppleSignIn(res));
    }).catch((error: AppleSignInErrorResponse) => {
      this.store.dispatch(new fromAuth.AppleSignInError(error));
    });
  }

  doGoogleSignUp() {
    this.showErrors = false;
    this.showMainError = false;
    this.trackingService.traceAction('gogle_login_click', { type: 'Registration' });
    this.store.dispatch(new fromAuth.ShowLoader());
    if (this.cordova) {
      this.googlePlus.login({}).then((res) => {
        this.store.dispatch(new fromAuth.GoogleSignIn({
          id: res.userId,
          email: res.email,
          name: res.displayName,
          authToken: res.accessToken
        }));
      })
      .catch(err => {
        console.log(`Error ${JSON.stringify(err)}`);
        this.store.dispatch(new fromAuth.GoogleSignInError(err))
      });
    } else {
      this.socialAuthService.initState.toPromise().then(() => {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
          this.store.dispatch(new fromAuth.GoogleSignIn({
            id: res.id,
            email: res.email,
            name: res.name,
            authToken: res.authToken
          }));
        })
        .catch(err => {
          console.log(`Error ${JSON.stringify(err)}`);
          this.store.dispatch(new fromAuth.GoogleSignInError(err))
        });
      })
    }
  }

  openRegister() {
    this.store.dispatch(new OpenSignUp());
  }

  openLogin() {
    this.store.dispatch(new OpenSignIn());
  }

}
