import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
// Actions
import * as CoreStore from '../store/core';
import * as ProfileStore from '../store/profile';
import * as fromFunz from '../../shared-stores/funz/store';
import * as fromBookmarks from '../../shared-stores/bookmarks';
import * as fromActivities from '../store/activities';
import * as fromCities from '../store/cities';

// Reducers
import * as fromApp from '../store/app.reducers';
import * as fromCore from '../../core/store/core';
import { Funz } from './../../funz/funz.model';
import { ActivityTypes } from '../store/activities';
import { APP_PAGES } from '../../pages';
import * as CoreActions from '../store/core/core.actions';
import { Storage } from '@ionic/storage';
import { ModalController, NavController } from '@ionic/angular';
import { ENV } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { TrackingService } from '../services/tracking.service';
import { LocationSelectorComponent } from '../../components/location-selector/location-selector';
import { recentCitiesStorageKey } from '../store/cities';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { has, reduce, set } from 'lodash';
import { FunzApiService } from '../services/funz-api.service';
import { RerequestLocationAccessComponent } from '../../components/rerequst-location-access/rerequst-location-access';
import { GeneralHelperService } from '../services/general-helper.service';
import { IS_ONLINE_IL, IS_ONLINE_UK, NO_CITY_ID } from 'src/app/funz/city.model';

@Injectable()
export class AppApi {

  cordova = !!(window as any).cordova;
  siteheaders$ = this.store.select(CoreStore.getSiteHeaders);
  authHeaders$ = this.store.select(CoreStore.getAuthHeaders);
  isSignedIn$ = this.store.select(CoreStore.getIsSignedIn);
  bookmarks$ = this.store.select(fromBookmarks.getBookmarks);
  emptyWishlist$ = this.store.select(fromBookmarks.getIsEmptyBookmarks);
  loadingBookmarks$ = this.store.select(fromBookmarks.getLoadingBookmarks);
  locationStorageCheck = false;
  canShowCookiesMsg: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private store: Store<fromApp.IAppState>,
    private storage: Storage,
    private cookieService: CookieService,
    private modalCtrl: ModalController,
    private trackingService: TrackingService,
    private geolocation: Geolocation,
    public navCtrl: NavController,
    private funzApi: FunzApiService,
    private ghs: GeneralHelperService,
  ) {}

  getRequestedPageParams() {
    let paramsAsAction;
    this.store
      .select(CoreStore.getRequestedSubPageParams).pipe(
      take(1))
      .subscribe(p => (paramsAsAction = p));
    return paramsAsAction;
  }

  getDeeplinkedPageParams() {
    let deeplinkedParams;
    this.store
      .select(CoreStore.getDeeplinkedPageParams).pipe(
      take(1))
      .subscribe(p => {
        (deeplinkedParams = p);
      });
    return deeplinkedParams;
  }

  getPwaDeeplinkedSlug() {
    let deeplinkedSlug;
    this.store
      .select(CoreStore.getPwaDeeplinkedSlug).pipe(
      take(1))
      .subscribe(p => {
        (deeplinkedSlug = p);
      });
    return deeplinkedSlug;
  }

  fetchHeaders() {
    this.store.dispatch(new CoreStore.FetchHeaders());
  }

  checkFirstTime() {
    this.store.dispatch(new CoreStore.CheckFirstTime());
  }

  fetchLastSelectedLocation() {
    this.store.dispatch(new fromCities.FetchSelectedCity());
  }

  setHeaders(headers) {
    this.store.dispatch(new CoreStore.SetHeaders(headers));
  }

  requestPage(pageConfig: { page: string; params?: any }) {
    this.store.dispatch(new CoreStore.RequestPage(pageConfig));
  }

  requestPagePwa(pageConfig: { page: string; params?: any }) {
    this.store.dispatch(new CoreStore.RequestPagePwa(pageConfig));
  }

  setRequestedPageParams(params: any) {
    this.store.dispatch(new CoreStore.SetRequestedPageParams(params));
  }

  updateProfile(data) {
    this.store.dispatch(new ProfileStore.UpdateProfile(data));
  }

  updateDevice(data) {
    this.store.dispatch(new ProfileStore.UpdateDevice(data));
  }

  requireAuth() {
    this.store.dispatch(new CoreStore.AuthInvalid());
  }

  appsFlyerURLHandler(url) {
    this.store.dispatch(new CoreStore.CheckOpenDeeplink(url));
  }

  appsFlyerSuccess(result) {
    this.store.dispatch(new CoreStore.CheckInstallDeeplink(result));
  }

  goToPage(page: string) {
    this.store.dispatch(new CoreStore.SetRootPage(page));
  }

  bookmarkFunz(funz: Funz) {
    this.store.dispatch(new fromFunz.UpdateFunz(funz));
  }

  fetchBookmarks() {
    this.store.dispatch(new fromBookmarks.FetchBookmarks());
  }

  goToActivityDeeplink(activityType: ActivityTypes, params = {}) {
    this.store.dispatch(
      new fromActivities.SetActivityType({ activityType, params })
    );
    this.store.dispatch(
      new CoreStore.SetDeepLinkedPage(APP_PAGES.ActivitiesPage)
    );
  }

  follow(funz: Funz) {
    this.store.dispatch(new fromFunz.FollowFunzOwner(funz));
  }

  unfollow(funz: Funz) {
    this.store.dispatch(new fromFunz.UnfollowFunzOwner(funz));
  }

  onConnectionChange(isOnline) {
    this.store.dispatch(new CoreStore.SetConnectionStatus(isOnline));
  }

  onLocationStatusChange(isLocationAvailable) {
    this.store.dispatch(
      new CoreStore.SetLocationPermission(isLocationAvailable)
    );
  }

  requireAuthConfirm() {
    this.store.dispatch(new CoreStore.OpenAuthConfirmDialog());
  }

  handleDeeplink(actionType, params) {
    this.store.dispatch(new CoreStore.HandleDeeplink({ actionType, params }));
  }

  async checkPWALocation() {
    const lastSelectedCity = await this.storage.get('lastSelectedCity');
    if (!lastSelectedCity) {
      const locationApproval = await this.showCheckLocationModal();
      if (locationApproval) {
        this.requestLocation();
      } else {
        this.store.dispatch(new fromCities.SetSelectCity({ id: NO_CITY_ID }));
        this.store.dispatch(new fromCore.SetLastSelectedCityId(NO_CITY_ID));
      }
    }
  }

  async requestUserLocation() {
    let locationApproval = await this.storage.get('locationApproval');
    if (!locationApproval) {
      locationApproval = await this.showCheckLocationModal();
    }
    if (locationApproval) {
      locationApproval = await this.requestLocation();
    }
    return locationApproval;
  }

  async requestLocation(dontShowModals = false) {
    let settings: any = {};
    if ((window as any).cordova && (window as any).cordova.platformId && (window as any).cordova.platformId === 'android') {
      settings = { maximumAge: 0, timeout: 2000, enableHighAccuracy: true };
    }

    try {
      const location = await this.geolocation.getCurrentPosition(settings);
      if (location) {
        this.fetchNearestCity(location.coords);
        await this.storage.set('locationApproval', true).catch((error: Error) => this.ghs.logStorageError(error));
        this.trackingService.traceAction('browsers_location_access_approval', { approved: true } );
      } else {
        this.fetchNearestCity();
        await this.storage.remove('locationApproval');
        this.trackingService.traceAction('browsers_location_access_approval', { approved: false } );
      }
      return true;
    } catch (error) {
      this.fetchNearestCity();
      await this.storage.remove('locationApproval');
      this.trackingService.traceAction('browsers_location_access_approval', { approved: false } );
      const status = await new Promise((resolve, reject) => {
        (window as any).cordova.plugins.diagnostic.getLocationAuthorizationStatus((success) => {
          resolve(success);
        }, (error) => {
          resolve(error);
        });
      });
      if (dontShowModals) {
        return true;
      }
      const userRequestedToOpenSettings = await this.showRerequestLocationModal();
      if (userRequestedToOpenSettings) {
        if (status === 'NOT_REQUESTED') {
          (window as any).cordova.plugins.diagnostic.switchToLocationSettings();
        }
        if (status === 'denied_always') {
          (window as any).cordova.plugins.diagnostic.switchToSettings();
        }
      }
      return false;
    }
  }

  fetchNearestCity(coords = null) {
    let request: any;
    if (this.cordova && !coords) {
      this.store.dispatch(new fromCities.SetSelectCity({ id: NO_CITY_ID }));
      this.store.dispatch(new fromCore.SetLastSelectedCityId(NO_CITY_ID));
      return;
    }
    if (coords) {
      const { latitude, longitude } = coords;
      request = this.funzApi.requestRoot(
        `latitude=${latitude}&longitude=${longitude}`,
        'cities/nearest'
      );
    } else {
      request = this.funzApi.requestRoot('', 'cities/nearest');
    }
    request.pipe(take(1)).subscribe((response) => {
      const { city: { id } } = response;
      if (id === IS_ONLINE_IL || id === IS_ONLINE_UK) {
        this.storage.set('isOnline', 1);
        this.store.dispatch(new fromCities.SetOnline(1));
        this.store.dispatch(new fromCities.SetUserCity({ id: NO_CITY_ID, isDefault: true }));
      } else {
        this.store.dispatch(new fromCities.SetUserCity({ id, isDefault: true }));
      }
    });
  }

  async setDefaultCity() {
    this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(
      take(1))
      .subscribe(suffix => {
        const city = { id: suffix === 'uk' ? 1 : suffix === 'il' ? 4 : 61 };
        this.store.dispatch(new fromCities.SetSelectCity({ city }));
        this.store.dispatch(new fromCore.SetLastSelectedCityId(city.id));
        Promise.resolve();
      });
  }

  showCheckLocationModal() {
    this.trackingService.tracePageView('location_request_pageview');
    return new Promise(async resolve => {
      const modal = await this.modalCtrl.create({
        component: LocationSelectorComponent,
        cssClass: 'location-selector',
        backdropDismiss: false
      });
      await modal.present();
      modal.onDidDismiss().then((ret: any) => {
        this.canShowCookiesMsg.next(true);
        this.trackingService.traceAction('location_request_approval_decline', { approved: ret.data });
        if (!this.cordova && ret.data) {
          this.handlePWAPermission().then(result => resolve(result));
        } else {
          resolve(ret.data);
        }
      });
    });
  }

  showRerequestLocationModal() {
    return new Promise(async resolve => {
      const modal = await this.modalCtrl.create({
        component: RerequestLocationAccessComponent,
        cssClass: 'rerequest-location-access',
        backdropDismiss: false
      });
      await modal.present();
      modal.onDidDismiss().then((ret: any) => {
        resolve(ret.data);
      });
    });
  }

  resetDeeplinkedPage() {
    this.store.dispatch(new CoreStore.ResetDeepLinkedPage());
  }

  initializePWA() {
    this.setPWACookie();
    this.store.dispatch(new CoreStore.InitializePWA());
  }

  resetPWADeeplink() {
    this.store.dispatch(new CoreStore.ResetPWADeeplink());
  }

  setLocalStorageExpiration(key: string, expirationTime = 24 * 60 * 60 * 1000) {
    this.storage.get('expiration').then((expiration = {}) => {
      const expirationObject = { ...expiration };
      const nextDay = (Date.now() + expirationTime).toString();
      expirationObject[key] = nextDay;
      this.storage.set('expiration', expirationObject)
      .catch((error: Error) => this.ghs.logStorageError(error));
    });
  }

  isLocalStorageExpired(key) {
    return new Promise(resolve => {
      this.storage.get('expiration').then(expiration => {
        const expirationTimestamp = expiration ? expiration[key] : 0;
        resolve(Date.now() > expirationTimestamp);
      });
    });
  }

  isLocalStorageUnsetOrExpired(key) {
    return new Promise(resolve => {
      this.storage.get('expiration').then(expiration => {
        if (expiration[key]) {
          resolve(Date.now() > expiration[key]);
        } else {
          resolve(true);
        }
      });
    });
  }

  hidePWAFooter() {
    this.store.dispatch(new CoreStore.HidePwaFooter());
  }

  showPWAFooter() {
    this.store.dispatch(new CoreStore.ShowPwaFooter());
  }

  setPWACookie() {
    const oldPwaParams = ['pwa', 'pwa1'];
    const pwaParam = 'pwa2';
    oldPwaParams.forEach((param) => this.cookieService.delete(param, '/', ENV.core_domain));
    const date = new Date();
    date.setTime(+date + (365 * 86400000));
    this.cookieService.set(pwaParam, 'true', date, '/', ENV.core_domain);
  }

  resetApp() {
    this.storage.remove('lastSelectedCity');
    this.storage.remove(recentCitiesStorageKey);
    this.store.select(CoreStore.getIsAuthAndSiteHeadersSaved).subscribe(saved => {
      if (saved) {
        location.pathname = '';
      }
    });
  }

  handleMarketingParams(params) {
    const relevantKeys = [
      'order_utm_source',
      'order_utm_medium',
      'order_utm_campaign',
      'order_utm_content',
      'order_utm_term',
      'order_marketing_channel',
      'order_ad_id',
      'affiliate',
      'landing_url',
      'referring_url',
      'marketing_channel',
      'ad_id',
      'af_banner_name',
      'referer'
    ];

    const relevantValues = reduce(relevantKeys, (acc, key) => {
      let actualKey = key;
      if (key.includes('order_')) {
        actualKey = key.split('order_').pop();
      }
      if (has(params, key)) {
        set(acc, actualKey, params[key]);
      }
      if (has(params, actualKey)) {
        set(acc, actualKey, params[actualKey]);
      }
      return acc;
    }, {});
    this.trackingService.saveUrlParams(relevantValues);
  }

  loadMarketingParams() {
    this.store.dispatch(new CoreActions.LoadMarketingParams());
  }

  private handlePWAPermission() {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(() => resolve(true), () => resolve(false))
    })
  }

}
