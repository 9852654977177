import { ProfileActions, ProfileActionTypes } from './profile.actions';
import { NO_CITY_ID } from '../../../funz/city.model';
import { ISubscription } from '../activities';

export interface ICreditItem {
  description: string;
  initial_amount: string;
  spent_amount: string;
  created_at: string;
  expires_at?: string;
}

export interface ILoyaltyPointItem {
  credit_value: string;
  funz_title: string;
  expires_at: string;
  order_cancelled: boolean;
}

export interface IInvite {
  referral_credits_amount: string;
  invite_url: string;
  twitter_invite_url: string;
  canonical_url: string;
  whatsapp_invite_url: string;
  facebook_invite_url: string;
  mail_invite_url: string;
  fb_messenger_invite_url: string;
}

export interface ICredits {
  created: Date;
  api_version: string;
  credits: ICreditItem[];
}

export interface IProfileState {
  user_id: string;
  name: string;
  email: string;
  birthday: Date;
  phone: string;
  local_phone: string;
  total_credits: string;
  total_loyalty_points: number;
  avatar_url: string;
  subscribed_to_marketing_emails: boolean;
  city_id: number;
  unread_notifications_count: number;
  unread_conversations_count: number;
  profile_background: string;
  default_background: string;
  is_empty_avatar: boolean;
  points_message: string;
  invite: IInvite;
  credits?: ICreditItem[];
  loyalty_points?: ILoyaltyPointItem[];
  form?: {
    isSubmitted: false;
    form_success: boolean;
    form_errors: {};
  };
  has_active_subscriptions: boolean;
  provider: string;
  sign_in_count: number;
  onesignal_paying: boolean;
  top_category: string;
  is_seller: boolean;
  is_affiliate: boolean;
  subscription: ISubscription;
  needs_to_fb_onboard: boolean;
  fb_id: string;
}

const initialState: IProfileState = {
  user_id: '',
  name: '',
  email: '',
  birthday: undefined,
  phone: '',
  local_phone: null,
  total_credits: '',
  total_loyalty_points: 0,
  points_message: '',
  avatar_url: '',
  city_id: NO_CITY_ID,
  subscribed_to_marketing_emails: false,
  unread_notifications_count: 0,
  unread_conversations_count: 0,
  profile_background: '',
  default_background:
    'https://res.cloudinary.com/dnn7h5tec/image/upload/prod-site-assets/no-user-mobile-background.jpg',
  is_empty_avatar: true,
  invite: {
    referral_credits_amount: '',
    invite_url: '',
    twitter_invite_url: '',
    canonical_url: '',
    whatsapp_invite_url: '',
    facebook_invite_url: '',
    mail_invite_url: '',
    fb_messenger_invite_url: ''
  },
  form: {
    isSubmitted: false,
    form_success: false,
    form_errors: {}
  },
  has_active_subscriptions: false,
  provider: '',
  sign_in_count: 0,
  onesignal_paying: false,
  top_category: null,
  is_seller: false,
  is_affiliate: false,
  subscription: null,
  needs_to_fb_onboard: false,
  fb_id: null,
};

export function profileReducer(state = initialState, action: ProfileActions) {
  switch (action.type) {
    case ProfileActionTypes.RESET_PROFILE: {
      return {
        ...initialState
      };
    }

    case ProfileActionTypes.UPDATE:
    case ProfileActionTypes.UPDATE_PROFILE_FORM: {
      const { payload, payload: { avatar_url } } = action;
      const isEmptyAvatar = avatar_url ? avatar_url.includes('no-user') : false;
      const profileBackground = avatar_url
        ? isEmptyAvatar ? state.default_background : avatar_url
        : state.profile_background;
      return {
        ...state,
        ...payload,
        profile_background: profileBackground,
        is_empty_avatar: isEmptyAvatar
      };
    }

    case ProfileActionTypes.UPDATE_PROFILE_SUCCESS: {
      const { payload: { user } } = action;
      return {
        ...state,
        ...user,
        form: {
          isSubmitted: true,
          form_success: true,
          form_errors: {}
        }
      };
    }

    case ProfileActionTypes.UPDATE_PHONE_SUCCESS: {
      return {
        ...state,
        phone: action.payload,
        local_phone: action.payload
      };
    }

    case ProfileActionTypes.UPDATE_PROFILE_FAILURE: {
      const { payload: { errors } } = action;
      return {
        ...state,
        form: {
          isSubmitted: true,
          form_success: false,
          form_errors: { ...errors }
        }
      };
    }

    case ProfileActionTypes.CLEAR_EDIT_FORM: {
      return {
        ...state,
        form: {
          isSubmitted: false,
          form_success: false,
          form_errors: {}
        }
      };
    }

    case ProfileActionTypes.TOGGLE_MARKETING_OPT_IN:
      return {
        ...state,
        subscribed_to_marketing_emails: !state.subscribed_to_marketing_emails
      };

    /* GET_PROFILE_CREDITS_SUCCESS */
    case ProfileActionTypes.GET_PROFILE_CREDITS_SUCCESS: {
      const { payload: { credits } } = action;
      return {
        ...state,
        credits: [...credits]
      };
    }

    /* GET_PROFILE_INVITES_SUCCESS */
    case ProfileActionTypes.GET_PROFILE_INVITES_SUCCESS: {
      const { payload: { invites } } = action;
      return {
        ...state,
        invite: invites
      };
    }

    /* GET_PROFILE_LOYALTY_POINTS_SUCCESS */
    case ProfileActionTypes.GET_PROFILE_LOYALTY_POINTS_SUCCESS: {
      const { payload: { loyalty_points } } = action;
      return {
        ...state,
        loyalty_points: [...loyalty_points]
      };
    }

    case ProfileActionTypes.GET_PROFILE_SUBSCRIPTION: {
      return {
        ...state,
        subscription: null
      };
    }

    case ProfileActionTypes.GET_PROFILE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscription: action.subscription
      };
    }

    case ProfileActionTypes.GET_PROFILE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        subscription: null
      };
    }

    default:
      return state;
  }
}
