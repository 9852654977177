import { APP_PAGES } from './pages';

interface PageUrlGenerator {
  generateURL(pageName: string, params: any): string;
}

class SearchResultPageUrlGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'search-results';
  }
}

class SearchPageUrlGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'search';
  }
}

class WishlistPageUrlGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'my/bookmarks';
  }
}

class SharePageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'invite';
  }
}

class SettingsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'contact-us';
  }
}

class OnboardingPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `onboarding`;
  }
}

class DiscoverPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return 'home';
  }
}

class OrderPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `funz/${params.id}/book/${params.event_id || ''}`;
  }
}

class FeedbacksPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `funz/${params.slug}/feedbacks`;
  }
}

class OrdersPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/orders`;
  }
}

class NotificationsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/notifications`;
  }
}

class HostPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `host/${params.id}`;
  }
}

class ConversationsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/conversations`;
  }
}

class ChatPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/conversations/${params.id}`;
  }
}

class RequestContactPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `funz/${params.id}/request-contact`;
  }
}

class RequestDatePageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `funz/${params.id}/request-date`;
  }
}

class FunzPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `funz/${params.id}`;
  }
}

class ProfilePageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/profile`;
  }
}

class ProfileEditPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/profile/edit`;
  }
}

class ProfileCreditsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/credit`;
  }
}

class OrderDetailPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/orders/${params.id}`;
  }
}

class LoyaltyBalancePageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/loyalty_points`;
  }
}

class CategoryPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `category/${params.slug}`;
  }
}

class LandingPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `s/${params.slug}`;
  }
}

class MenuPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `menu`;
  }
}

class SubscriptionSettingsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `subscription`;
  }
}

class NotificationsSettingsPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `my/notifications-settings`;
  }
}

class LivePageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `live/${params.id}`;
  }
}

class GiftCardPageGenerator implements PageUrlGenerator {
  generateURL(pageName: string, params: any): string {
    return `gift-card`;
  }
}

export class PageToUrlTransformer {
  private paths = new Map();

  constructor() {
    this.paths.set(APP_PAGES.DiscoverPage, new DiscoverPageGenerator());
    this.paths.set(APP_PAGES.SearchPage, new SearchPageUrlGenerator());
    this.paths.set(APP_PAGES.SearchResultsPage, new SearchResultPageUrlGenerator());
    this.paths.set(APP_PAGES.WishlistPage, new WishlistPageUrlGenerator());
    this.paths.set(APP_PAGES.SharePage, new SharePageGenerator());
    this.paths.set(APP_PAGES.SettingsPage, new SettingsPageGenerator());
    this.paths.set(APP_PAGES.OnboardingPage, new OnboardingPageGenerator());
    this.paths.set(APP_PAGES.OrderPage, new OrderPageGenerator());
    this.paths.set(APP_PAGES.OrderDetailsPage, new OrderDetailPageGenerator());
    this.paths.set(APP_PAGES.FeedbacksPage, new FeedbacksPageGenerator());
    this.paths.set(APP_PAGES.OrdersPage, new OrdersPageGenerator());
    this.paths.set(APP_PAGES.NotificationsPage, new NotificationsPageGenerator());
    this.paths.set(APP_PAGES.ConversationsPage, new ConversationsPageGenerator());
    this.paths.set(APP_PAGES.HostPage, new HostPageGenerator());
    this.paths.set(APP_PAGES.RequestContactPage, new RequestContactPageGenerator());
    this.paths.set(APP_PAGES.FunzPage, new FunzPageGenerator());
    this.paths.set(APP_PAGES.RequestDatePage, new RequestDatePageGenerator());
    this.paths.set(APP_PAGES.ProfilePage, new ProfilePageGenerator());
    this.paths.set(APP_PAGES.ChatPage, new ChatPageGenerator());
    this.paths.set(APP_PAGES.ProfileEditPage, new ProfileEditPageGenerator());
    this.paths.set(APP_PAGES.ProfileCreditsPage, new ProfileCreditsPageGenerator());
    this.paths.set(APP_PAGES.LoyaltyBalancePage, new LoyaltyBalancePageGenerator());
    this.paths.set(APP_PAGES.LandingPage, new LandingPageGenerator());
    this.paths.set(APP_PAGES.CategoryPage, new CategoryPageGenerator());
    this.paths.set(APP_PAGES.MenuPage, new MenuPageGenerator());
    this.paths.set(APP_PAGES.SubscriptionSettingsPage, new SubscriptionSettingsPageGenerator());
    this.paths.set(APP_PAGES.NotificationsSettingsPage, new NotificationsSettingsPageGenerator());
    this.paths.set(APP_PAGES.LivePage, new LivePageGenerator());
    this.paths.set(APP_PAGES.GiftCardPage, new GiftCardPageGenerator());
  }

  public getUrlByPageName(pageName: string, params?: any) {
    const urlGenerator: PageUrlGenerator = this.paths.get(pageName);
    return urlGenerator.generateURL(pageName, params);
  }
}

export const PAGE_TO_URL_TRANSFORMER: PageToUrlTransformer = new PageToUrlTransformer();
