import { Action } from '@ngrx/store';
import { Funz, IFunzBookmarkResponse } from '../../../funz/funz.model';
import { Category, CategoryBox } from '../../../funz/category.model';
import { IonInfiniteScroll } from '@ionic/angular';
import { Feedback } from '../../../funz/feedback.model';
import { PersonalizedParam } from 'src/app/core/services/funz-api.service';
export const CATEGORIES_LOADING_LIMIT = null;

export const ORDER_FUNZ_FAILURE = '[Funz] ORDER_FUNZ_FAILURE';
export const FETCH_FUNZ = '[Funz] FETCH_FUNZ';
export const LOAD_FUNZ = '[Funz] LOAD_FUNZ';
export const LOAD_FUNZES = '[Funz] LOAD_FUNZES';
export const LOAD_FUNZES_ERROR = '[Funz] LOAD_FUNZES_ERROR';
export const FETCH_CATEGORY = '[Funz] FETCH_CATEGORY';
export const FETCH_CATEGORY_PAGE = '[Funz] FETCH_CATEGORY_PAGE';
export const FETCH_CATEGORY_PAGE_START = '[Funz] FETCH_CATEGORY_PAGE_START';
export const FETCH_CATEGORY_PAGE_END = '[Funz] FETCH_CATEGORY_PAGE_END';
export const FETCH_CATEGORY_DEEPLINK = '[Funz] FETCH_CATEGORY_DEEPLINK';
export const FETCH_DISCOVER_CATEGORIES = '[Funz] FETCH_DISCOVER_CATEGORIES';
export const FETCH_HOME_CATEGORIES = '[Funz] FETCH_HOME_CATEGORIES';
export const FETCH_HOME_CATEGORIES_ERROR = '[Funz] FETCH_HOME_CATEGORIES_ERROR';
export const LOAD_FEATURED_FUNZ = '[Funz] LOAD_FEATURED_FUNZ';
export const UPDATE_DISCOVER_LIMIT = '[Funz] UPDATE_DISCOVER_LIMIT';
export const FETCH_PERSONOLISED_FUNZES = '[Funz] FETCH_PERSONOLISED_FUNZES';
export const PERSONOLISED_HOME_FUNZES = '[Funz] PERSONOLISED_HOME_FUNZES';
export const PERSONOLISED_FUNZ_FUNZES = '[Funz] PERSONOLISED_FUNZ_FUNZES';
export const FETCH_CATEGORY_ROW = '[Funz] FETCH_CATEGORY_ROW';
export const LOAD_CATEGORY_ROW = '[Funz] LOAD_CATEGORY_ROW';
export const RESET_CATEGORY = '[Funz] RESET_CATEGORY';
export const VIEW_FUNZ = '[Funz] VIEW_FUNZ';
export const RESET_FUNZ = '[Funz] RESET_FUNZ';
export const UPDATE_FUNZ = '[Funz] UPDATE_FUNZ';
export const UPDATE_FUNZ_SUCCESS = '[Funz] UPDATE_FUNZ_SUCCESS';
export const FETCH_FUNZ_DEEPLINK = '[Funz] FETCH_FUNZ_DEEPLINK';
export const LOAD_FUNZ_DEEPLINK = '[Funz] LOAD_FUNZ_DEEPLINK';

export const FETCH_FUNZ_FEEDBACKS = '[Funz] FETCH_FUNZ_FEEDBACKS';
export const LOAD_FUNZ_FEEDBACKS = '[Funz] LOAD_FUNZ_FEEDBACKS';
export const UPDATE_BOOKMARKED_FUNZ = '[Funz] UPDATE_BOOKMARKED_FUNZ';
export const RESET_BOOKMARKED_FUNZ = '[Funz] RESET_BOOKMARKED_FUNZ';

export enum ActionTypes {
  SET_SELECTED_FUNZ = 'SET_SELECTED_FUNZ',

  FETCH_EVENTS = '[Funz] FETCH_EVENTS',
  FETCH_EVENTS_SUCCESS = '[Funz] FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILURE = '[Funz] FETCH_EVENTS_FAILURE',

  FETCH_FUNZ_EVENTS_SUCCESS = '[Funz Order] FETCH_FUNZ_EVENTS_SUCCESS',

  CALCULATE_PRICE = 'CALCULATE_PRICE',
  CALCULATE_PRICE_SUCCESS = 'CALCULATE_PRICE_SUCCESS',
  CALCULATE_PRICE_FAILURE = 'CALCULATE_PRICE_FAILURE',

  ORDER_FUNZ = 'ORDER_FUNZ',
  ORDER_FUNZ_RESPONSE = 'ORDER_FUNZ_RESPONSE',
  ORDER_FUNZ_SUCCESS = 'ORDER_FUNZ_SUCCESS',
  ORDER_FUNZ_FAILURE = 'ORDER_FUNZ_FAILURE',
  ORDER_FUNZ_CANCEL = 'ORDER_FUNZ_CANCEL',
  ORDER_FUNZ_PAYMENT_REDIRECT = 'ORDER_FUNZ_PAYMENT_REDIRECT',
  ORDER_FUNZ_PAYMENT_RESPONSE = 'ORDER_FUNZ_PAYMENT_RESPONSE',
  ORDER_FUNZ_PAYMENT_CLOSED = 'ORDER_FUNZ_PAYMENT_CLOSED',
  ORDER_FUNZ_STATUS_CHECK = 'ORDER_FUNZ_STATUS_CHECK',
  ORDER_FUNZ_STATUS_RESULT = 'ORDER_FUNZ_STATUS_RESULT',
  UPDATE_SEARCH_RESULTS = '[Funz] UPDATE_SEARCH_RESULTS',
  RESET_SEARCH_RESULTS = '[Funz] RESET_SEARCH_RESULTS',

  SET_ORDER_SELECTED_EVENT = 'SET_ORDER_SELECTED_EVENT',

  GET_ORDER_SELECTED_SEATS = 'GET_ORDER_SELECTED_SEATS',
  FETCH_ORDER_SELECTED_SEATS = 'FETCH_ORDER_SELECTED_SEATS',
  SET_ORDER_SELECTED_SEATS = 'SET_ORDER_SELECTED_SEATS',
  UPDATE_ORDER_SELECTED_SEATS = 'UPDATE_ORDER_SELECTED_SEATS',
  // CHECK_ORDER_SELECTED_SEATS_VALIDITY = 'CHECK_ORDER_SELECTED_SEATS_VALIDIT',
  CLEAR_ORDER_SELECTED_SEATS = 'CLEAR_ORDER_SELECTED_SEATS',

  SHOW_COUNTDOWN_TIMER = 'SHOW_COUNTDOWN_TIMER',
  START_COUNTDOWN_TIMER = 'START_COUNTDOWN_TIMER',
  STOP_COUNTDOWN_TIMER = 'STOP_COUNTDOWN_TIMER',

  SET_SELECTED_SEATS_BY_TYPE = 'SET_SELECTED_SEATS_BY_TYPE',
  SET_SELECTED_SEATS_BY_CATEGORY = 'SET_SELECTED_SEATS_BY_CATEGORY',

  UPDATE_FILTER_RESULTS = '[Funz] UPDATE_FILTER_RESULTS',
  RESET_FILTER_RESULTS = '[Funz] RESET_FILTER_RESULTS',

  UPDATE_FILTER = 'UPDATE_FILTER',
  RESET_FILTER = 'RESET_FILTER',
  FETCH_CATEGORIES_START = '[Funz] FETCH_CATEGORIES_START'
}

export const FUNZ_OWNER_FOLLOW = '[Funz] FUNZ_OWNER_FOLLOW';
export const FUNZ_OWNER_UNFOLLOW = '[Funz] FUNZ_OWNER_UNFOLLOW';
export const FUNZ_OWNER_FOLLOW_SUCCESS = '[Funz] FUNZ_OWNER_FOLLOW_SUCCESS';

export class FetchFunz implements Action {
  readonly type = FETCH_FUNZ;

  constructor(public payload: number) { }
}

export class LoadFunz implements Action {
  readonly type = LOAD_FUNZ;

  constructor(public payload: { [key: string]: Funz }) { }
}

export class FetchFunzDeeplink implements Action {
  readonly type = FETCH_FUNZ_DEEPLINK;

  constructor(public payload: string) { }
}

export class LoadFunzDeeplink implements Action {
  readonly type = LOAD_FUNZ_DEEPLINK;

  constructor(public payload: { [key: string]: Funz }) { }
}

export class LoadFunzes implements Action {
  readonly type = LOAD_FUNZES;

  constructor(public payload: [], public funzesByCategory = null) { }
}

export class LoadFunzesError implements Action {
  readonly type = LOAD_FUNZES_ERROR;

  constructor(public err: any) { }
}

export class FetchCategory implements Action {
  readonly type = FETCH_CATEGORY;

  constructor(public payload: string) { }
}

export class FetchCategoryPage implements Action {
  readonly type = FETCH_CATEGORY_PAGE;

  constructor(
    public payload: {
      id: number;
      slug?: string;
      page?: number;
      infiniteScrollInstance?: IonInfiniteScroll;
    }
  ) { }
}

export class FetchCategoryDeeplink implements Action {
  readonly type = FETCH_CATEGORY_DEEPLINK;

  constructor() { }
}

export interface ILoadCategoryEvent {
  category_boxes?: CategoryBox[];
  start: number;
  end: number | null;
  refresh?: boolean;
}

export class FetchCategoriesStart implements Action {
  readonly type = ActionTypes.FETCH_CATEGORIES_START;

  constructor(public payload: ILoadCategoryEvent) { }
}

export class FetchDiscoverCategories implements Action {
  readonly type = FETCH_DISCOVER_CATEGORIES;
}

export class FetchHomeCategories implements Action {
  readonly type = FETCH_HOME_CATEGORIES;
  constructor(public refresh = false) {}
}

export class LoadFeaturedFunz implements Action {
  readonly type = LOAD_FEATURED_FUNZ;

  constructor(public payload: Category) { }
}

export interface ICategoryRow {
  id: number;
  slug: string;
  lp: boolean;
  refresh?: boolean;
}

export class FetchPersonolisedFunzes implements Action {
  readonly type = FETCH_PERSONOLISED_FUNZES;
  constructor(public payload: { params: PersonalizedParam[], home?: boolean, funzId?: number }) { }
}

export class PersonolisedFuznFunzes implements Action {
  readonly type = PERSONOLISED_FUNZ_FUNZES;
  constructor(public payload: { funzes: any; param: PersonalizedParam; }[]) { }
}

export class PersonolisedHomeFunzes implements Action {
  readonly type = PERSONOLISED_HOME_FUNZES;
  constructor(public payload: { funzes: any; param: PersonalizedParam; }[]) { }
}

export class FetchCategoryRow implements Action {
  readonly type = FETCH_CATEGORY_ROW;

  constructor(public payload: ICategoryRow) { }
}

export class LoadCategoryRow implements Action {
  readonly type = LOAD_CATEGORY_ROW;

  constructor(
    public payload: {
      id: number;
      funzIds: number[];
      donationIds: number[];
      totalPages: number;
      loadedPages: number;
      mobileDisplayName: string;
      slug: string;
      imageUrl: string;
      imageUrlWide: string;
    }
  ) { }
}

export class FetchCategoryPageStart implements Action {
  readonly type = FETCH_CATEGORY_PAGE_START;
}

export class FetchCategoryPageEnd implements Action {
  readonly type = FETCH_CATEGORY_PAGE_END;
}

export class UpdateDiscoverLimit implements Action {
  readonly type = UPDATE_DISCOVER_LIMIT;
  constructor(public payload = 0) { }
}

export class ResetCategory implements Action {
  readonly type = RESET_CATEGORY;

  constructor(public payload: number) { }
}

export class ViewFunz implements Action {
  readonly type = VIEW_FUNZ;

  constructor(public payload: number) { }
}

export class ResetFunz implements Action {
  readonly type = RESET_FUNZ;

  constructor(public payload: number) { }
}

export class FetchFunzFeedbacks implements Action {
  readonly type = FETCH_FUNZ_FEEDBACKS;

  constructor(public payload: { funzId: number; page: number }) { }
}

export class LoadFunzFeedbacks implements Action {
  readonly type = LOAD_FUNZ_FEEDBACKS;
  constructor(public payload: { funzId: number; feedbacks: Feedback[] }) { }
}

export class UpdateFunz implements Action {
  readonly type = UPDATE_FUNZ;
  constructor(public payload: Funz) { }
}

export class UpdateFunzSuccess implements Action {
  readonly type = UPDATE_FUNZ_SUCCESS;
  constructor(public payload: IFunzBookmarkResponse) { }
}

export class UpdateBookmarkedFunz implements Action {
  readonly type = UPDATE_BOOKMARKED_FUNZ;
  constructor(public payload: number[]) { }
}

export class ResetBookmarkedFunz implements Action {
  readonly type = RESET_BOOKMARKED_FUNZ;
  constructor() { }
}

/**
 * SET_SELECTED_FUNZ
 */
export class SetSelectedFunzAction implements Action {
  readonly type = ActionTypes.SET_SELECTED_FUNZ;
  constructor(public payload: number) { }
}

/**
 * FETCH_EVENTS
 */
export class FetchEventsAction implements Action {
  readonly type = ActionTypes.FETCH_EVENTS;
  constructor(public payload: number) { }
}
export class FetchEventsSuccessAction implements Action {
  readonly type = ActionTypes.FETCH_EVENTS_SUCCESS;
  constructor(public payload) { }
}
export class FetchEventsFailureAction implements Action {
  readonly type = ActionTypes.FETCH_EVENTS_FAILURE;
  constructor() { }
}

/**
 * CALCULATE_PRICE
 */
export class CalculatePriceAction implements Action {
  readonly type = ActionTypes.CALCULATE_PRICE;
  constructor(public payload) { }
}
export class CalculatePriceSuccessAction implements Action {
  readonly type = ActionTypes.CALCULATE_PRICE_SUCCESS;
  constructor(public payload) { }
}
export class CalculatePriceFailureAction implements Action {
  readonly type = ActionTypes.CALCULATE_PRICE_FAILURE;
  constructor(public payload) { }
}

/**
 * ORDER_FUNZ
 */
export class OrderFunzAction implements Action {
  readonly type = ActionTypes.ORDER_FUNZ;
  constructor(public payload) { }
}
export class OrderFunzResponseAction implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_RESPONSE;
  constructor(public payload) { }
}
export class OrderFunzSuccessAction implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_SUCCESS;
  constructor(public payload) { }
}
export class OrderFunzFailureAction implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_FAILURE;
  constructor(public payload) { }
}
export class OrderFunzCancelAction implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_CANCEL;
  constructor() { }
}
export class OrderFunzPaymentRedirect implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_PAYMENT_REDIRECT;
  constructor(public payload) { }
}
export class OrderFunzPaymentResponse implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_PAYMENT_RESPONSE;
  constructor(public payload) { }
}
export class OrderFunzPaymentClose implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_PAYMENT_CLOSED;
  constructor() {}
}
export class OrderFunzStatusCheck implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_STATUS_CHECK;
  constructor(public payload) { }
}
export class OrderFunzStatusResult implements Action {
  readonly type = ActionTypes.ORDER_FUNZ_STATUS_RESULT;
  constructor(public payload) { }
}

export class FollowFunzOwner implements Action {
  readonly type = FUNZ_OWNER_FOLLOW;
  constructor(public payload: Funz) { }
}

export class UnfollowFunzOwner implements Action {
  readonly type = FUNZ_OWNER_UNFOLLOW;
  constructor(public payload: Funz) { }
}

export class UpdateSearchResults implements Action {
  readonly type = ActionTypes.UPDATE_SEARCH_RESULTS;
  constructor(public payload: any) { }
}

export class ResetSearchResults implements Action {
  readonly type = ActionTypes.RESET_SEARCH_RESULTS;
  constructor() { }
}

export class SetOrderSelectedEvent implements Action {
  readonly type = ActionTypes.SET_ORDER_SELECTED_EVENT;
  constructor(public payload: number) { }
}

export class GetOrderSelectedSeats implements Action {
  readonly type = ActionTypes.GET_ORDER_SELECTED_SEATS;
  constructor(public payload: number) { }
}

export class FetchOrderSelectedSeats implements Action {
  readonly type = ActionTypes.FETCH_ORDER_SELECTED_SEATS;
  constructor() { }
}

export class SetOrderSelectedSeats implements Action {
  readonly type = ActionTypes.SET_ORDER_SELECTED_SEATS;
  constructor(public payload: any) { }
}

export class UpdateOrderSelectedSeats implements Action {
  readonly type = ActionTypes.UPDATE_ORDER_SELECTED_SEATS;
  constructor(public payload: any) { }
}

export class ClearOrderSelectedSeats implements Action {
  readonly type = ActionTypes.CLEAR_ORDER_SELECTED_SEATS;
  constructor(public payload: any) { }
}

export class SetSelectedSeatsByType implements Action {
  readonly type = ActionTypes.SET_SELECTED_SEATS_BY_TYPE;
  constructor(public payload: any) { }
}

export class SetSelectedSeatsByCategory implements Action {
  readonly type = ActionTypes.SET_SELECTED_SEATS_BY_CATEGORY;
  constructor(public payload: any) { }
}

export class ShowCountdownTimer implements Action {
  readonly type = ActionTypes.SHOW_COUNTDOWN_TIMER;
  constructor(public payload: boolean) { }
}

export class StartCountdownTimer implements Action {
  readonly type = ActionTypes.START_COUNTDOWN_TIMER;
  constructor(public payload: any) { }
}

export class UpdateFilterResults implements Action {
  readonly type = ActionTypes.UPDATE_FILTER_RESULTS;
  constructor(public payload: any) { }
}

export class ResetFilterResults implements Action {
  readonly type = ActionTypes.RESET_FILTER_RESULTS;
  constructor() { }
}

export class FetchFunzEventsSuccess implements Action {
  readonly type = ActionTypes.FETCH_FUNZ_EVENTS_SUCCESS;
  constructor(public payload: { id: number; events: any[] }) { }
}

export class FetchHomeCategoriesError implements Action {
  readonly type = FETCH_HOME_CATEGORIES_ERROR;
  constructor(public err: any) { }
}

export type FunzActions =
  | FetchFunz
  | LoadFunz
  | LoadFunzes
  | LoadFunzesError
  | FetchCategory
  | FetchDiscoverCategories
  | FetchCategoryDeeplink
  | FetchCategoryPage
  | LoadFeaturedFunz
  | FetchPersonolisedFunzes
  | PersonolisedFuznFunzes
  | PersonolisedHomeFunzes
  | FetchCategoryRow
  | LoadCategoryRow
  | FetchCategoryPageStart
  | FetchCategoryPageEnd
  | UpdateDiscoverLimit
  | ResetCategory
  | FetchCategoryPageEnd
  | FetchFunzFeedbacks
  | LoadFunzFeedbacks
  | ViewFunz
  | ResetFunz
  | UpdateFunz
  | UpdateFunzSuccess
  | UpdateBookmarkedFunz
  | ResetBookmarkedFunz
  | UpdateFunzSuccess
  | SetSelectedFunzAction
  | FetchEventsAction
  | FetchEventsSuccessAction
  | FetchEventsFailureAction
  | FetchHomeCategories
  | CalculatePriceAction
  | CalculatePriceSuccessAction
  | CalculatePriceFailureAction
  | OrderFunzAction
  | OrderFunzSuccessAction
  | OrderFunzFailureAction
  | OrderFunzCancelAction
  | OrderFunzResponseAction
  | OrderFunzPaymentRedirect
  | OrderFunzPaymentResponse
  | OrderFunzPaymentClose
  | OrderFunzStatusCheck
  | OrderFunzStatusResult
  | FollowFunzOwner
  | UnfollowFunzOwner
  | UpdateSearchResults
  | ResetSearchResults
  | SetOrderSelectedEvent
  | GetOrderSelectedSeats
  | FetchOrderSelectedSeats
  | SetOrderSelectedSeats
  | UpdateOrderSelectedSeats
  | ClearOrderSelectedSeats
  | SetSelectedSeatsByType
  | SetSelectedSeatsByCategory
  | ShowCountdownTimer
  | StartCountdownTimer
  | UpdateFilterResults
  | ResetFilterResults
  | FetchCategoriesStart
  | FetchFunzEventsSuccess
  | FetchFunzDeeplink
  | LoadFunzDeeplink
  | FetchHomeCategoriesError;
